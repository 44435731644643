import "./App.css";
import React from "react";
import ZenPaper from "./components/ZenPaper/ZenPaper";

function App() {
  return (
    <ZenPaper
      zenYourLife="https://anima-uploads.s3.amazonaws.com/projects/609a44f079ac9411703b13df/releases/60a22331ef81b78f4ef51325/img/zen-your-life@1x.png"
      stayTuned="https://anima-uploads.s3.amazonaws.com/projects/609a44f079ac9411703b13df/releases/60a2237c229b61dce7edfe7d/img/stay-tuned@1x.png"
      packages="https://anima-uploads.s3.amazonaws.com/projects/609a44f079ac9411703b13df/releases/60a22331ef81b78f4ef51325/img/packages@1x.png"
      theStory="https://anima-uploads.s3.amazonaws.com/projects/609a44f079ac9411703b13df/releases/60a2237c229b61dce7edfe7d/img/the-story@1x.png"
    />
  );
}

export default App;
