import React from "react";
import "./ZenPaper.css";

function ZenPaper(props) {
  const { zenYourLife, stayTuned, packages, theStory } = props;

  return (
    <div class="container-center-horizontal">
      <div className="zenpaper screen">
        <img className="zen-your-life" src={zenYourLife} />
        <div className="auto-video">
          <video
            src="https://anima-uploads.s3.amazonaws.com/projects/609a44f079ac9411703b13df/files/whatsapp-video-2021-04-29-at-14-26-42.mp4"
            loop
            autoplay="autoplay"
            playsinline
            muted
          ></video>
        </div>
        <img className="stay-tuned" src={stayTuned} />
        <img className="packages" src={packages} />
        <img className="the-story" src={theStory} />
        <div className="vimeo-video">
          <iframe
            style={{ border: 0 }}
            width="100%" 
            height="100%" 
            frameborder="0"
            src="https://player.vimeo.com/video/486813368"
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default ZenPaper;
